import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Alert} from "@mui/material";
import Swal from "sweetalert2";

import {IProjectSite} from '../../interfaces/types';
import apiManager from "../../utils/apiManager";
import {useUserContext} from "../../components/UserContext/UserContext";
import ComboBox, {IOptions} from "../../components/ComboBox/ComboBox";
import {GridBreak} from '../../components/GridUtils/GridBreak';
import ProjectSiteSelect from "../../components/ProjectSiteSelect/projectSiteSelect";


const Scan = () => {
    const {state} = useUserContext();

    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [personId, setPersonId] = useState<string>("")
    const [userProjectSites, setUserProjectSites] = useState<IProjectSite[]>([])
    const [barcode, setBarcode] = useState<string>("")
    const [selectedProject, setSelectedProject] = useState<IOptions | null>(null)
    const [selectedSite, setSelectedSite] = useState<IOptions | null>(null)

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            submit().catch(e => console.error(e));
        }
    };

    const handleMouseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        submit().catch(e => console.error(e));
    }

    useEffect(() => {
        getUserProjectList().catch((e) => {
            console.error(e)
        });
    }, [])

    async function getUserProjectList() {
        try {
            let res = await apiManager.getUserProjectSites()
            setUserProjectSites(res)
        } catch (e) {
            //TODO: handle error
            console.error(e);
        }
    }

    async function submit() {
        setError(false)
        setErrorMessage("")
        try {
            if (selectedProject === null) {
                setError(true)
                setErrorMessage("Project not selected")
                return
            }
            if (selectedSite === null) {
                setError(true)
                setErrorMessage("Site not selected")
                return
            }

            let project: IProjectSite | undefined = userProjectSites.find((project: IProjectSite) => project.projectCode === selectedProject.code && project.siteCode === selectedSite.code)
            let validatedProject: IProjectSite = await validateInput(project)

            await apiManager.addMnLabel( personId, barcode, validatedProject.projectCode, validatedProject.siteCode)

            Swal.fire({
                title: "Success",
                text: "Label added",
                icon: "success",
                confirmButtonText: "Ok"
            })
            setSelectedProject(null)
            setSelectedSite(null)
            setError(false)
            setBarcode("")
            setPersonId("")
        } catch (e: any) {
            console.error(e);
            if (e.name === "AxiosError") {
                setError(true)
                setErrorMessage(e.response.data.statusMessage)
                return
            }
            setError(true)
            setErrorMessage(e.message)
        }
    }

    async function validateInput(project: IProjectSite | undefined) {
        if (project === undefined) {
            throw new Error("Project not found")
        }
        if (personId === "") {
            throw new Error("Missing kennitala")
        }
        if (barcode === "") {
            throw new Error("Barcode missing")
        }
        if (project.projectCode === "") {
            throw new Error("Project code missing")
        }
        if (personId.length !== 10) {
            throw new Error("Kennitala must be 10 digits")
        }
        if (barcode.length !== 15) {
            throw new Error("Barcode must be 15 digits")
        }
        return project
    }

    return (
        <Container maxWidth={'xl'} sx={{pt: '50px'}}>
            <Grid
                container
                spacing={2}
                direction="row"
            >
                <Grid item xs={12}>
                    <Typography variant={'h4'}>
                        Scan
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body1'}>
                        To submit, please scan a valid mn-barcode, enter the kennitala of the person the label is for and select a project and site.
                    </Typography>
                </Grid>
                <GridBreak/>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="outlined-barcode"
                        label="Barcode"
                        value={barcode}
                        autoFocus
                        onKeyDown={handleKeyPress}
                        fullWidth
                        type="tel"
                        onChange={(e) => setBarcode(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="outlined-ssn"
                        label="Kennitala"
                        value={personId}
                        onKeyDown={handleKeyPress}
                        fullWidth
                        type="tel"
                        onChange={(e) => setPersonId(e.target.value)}
                    />
                </Grid>
                <ProjectSiteSelect
                    userProjectSites={state.user.projectSites.filter((project: IProjectSite) => project.allowMnScan)}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    selectedSite={selectedSite}
                    setSelectedSite={setSelectedSite}
                    handleKeyPress={handleKeyPress}
                />
                <Grid item alignItems="stretch" xs={2} style={{display: "flex"}}>
                    <Button
                        variant="contained"
                        onClick={handleMouseClick}
                    >
                        Submit
                    </Button>
                </Grid>
                <GridBreak/>
                {error &&
                    <Grid item xs={10}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
export default Scan;