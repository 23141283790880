import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    Navigate
} from "react-router-dom";

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import NavBar from "./components/NavBar/NavBar";
import Scan from "./pages/scan/Scan";
import Login from "./pages/login/Login";
import Page404 from "./pages/Page404";

import {IProjectSite, IRoute} from "./interfaces/types"
import apiManager from "./utils/apiManager";
import Loading from "./components/Loading/Loading";
import {useUserContext} from "./components/UserContext/UserContext";
import BoxScan from "./pages/boxScan/BoxScan";
import Home from "./pages/home/Home";

function App() {
    const {state, dispatch} = useUserContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        isLoggedIn().catch((e) => {
            console.error(e)
        })
    }, []);

    const isLoggedIn = async () => {
        try {
            let res = await apiManager.isLoggedIn();
            if (res.authenticated) {
                dispatch({
                    type: 'SET_USER',
                    payload: {authenticated: true, name: res.name, ssn: res.ssn, projectSites: res.projectSites}
                });
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const routes: Array<IRoute> = [
        {
            name: "Login",
            element: <Login/>,
            path: '/',
            showInMenu: false,
            private: false
        },
        {name: "MN Scan", element: <Scan/>, path: '/scan', showInMenu: true, private: true},
        {name: "Box Scan", element: <BoxScan/>, path: '/box-scan', showInMenu: true, private: false},
    ]

    routes.push({
        name: "Home",
        element: <Home routes={routes.filter(r => r.showInMenu)}/>,
        path: '/home',
        showInMenu: false,
        private: false,
    },)

    function PrivateRoute(props: { userProjectSites: IProjectSite[], allowedUserGroups: string[] | undefined }) {
        if (isLoading) {
            return <Loading/>
        } else {
            if(state.user.authenticated) {
                if (props.userProjectSites.length === 0) {
                    return <Navigate to={"/"}/>
                }

                if(props.allowedUserGroups === undefined || props.allowedUserGroups.length === 0) {
                    return <Outlet/>
                }

                //if projectSites includes the projectCode, return the outlet
                if(props.allowedUserGroups.some(r=> props.userProjectSites.map(p => p.projectCode).includes(r))) {
                    return <Outlet/>
                }
            }
            return <Navigate to={"/"}/>
        }
    }

    return (
        <div style={{display: "flex", minHeight: "90vh", flexDirection: "column"}}>
            <Router>
                <NavBar userName={state.user?.name} pages={routes} authenticated={state.user.authenticated}/>
                <Routes>
                    {routes.map((r: IRoute, index: number) => {
                        if (r.private) {
                            return (
                                <Route key={index} path={r.path} element={<PrivateRoute userProjectSites={state.user.projectSites} allowedUserGroups={r.allowedUserGroups}/>}>
                                    <Route key={index} path={""} element={<Scan/>}/>
                                </Route>
                            )
                        } else {
                            return <Route key={index} path={r.path} element={r.element}/>
                        }
                    })}
                    <Route path='*' element={<Page404/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
