import React, {forwardRef} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface IProps {
    options: IOptions[],
    id: string,
    label: string
    value: any
    setValue: (a: any) => void
    disabled?: boolean
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

export interface IOptions {
    label: string,
    code: string
}


const ComboBox = forwardRef((props: IProps, ref) => {
    return (
        <Autocomplete
            disablePortal
            disabled={props.disabled}
            value={props.value}
            onChange={(event: any, newValue: string | null) => {
                props.setValue(newValue);
            }}
            id={props.id}
            options={props.options}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={(params) =>
                <TextField
                    {...params}
                    inputRef={ref}
                    label={props.label}
                    onKeyDown={props.onKeyDown}
                />}
        />
    );
})

export default ComboBox