import React, {useEffect} from "react";
import {Container, Card, CardContent, Typography, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {Scanner} from "@mui/icons-material";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {IRoute} from "../../interfaces/types";

const Home = (props: { routes: IRoute[] }) => {

    const [cardElements, setCardElements] = React.useState<JSX.Element[]>([])

    useEffect(() => {
        createCards()
    }, [])

    const createCards = () => {
        let cards: IRoute[] = []
        let cardElements: JSX.Element[] = []
        for (const route of props.routes) {
            cardElements.push(
                <Grid item xs={3} key={route.path}>
                    <Link to={route.path} style={{textDecoration: 'none'}}>
                        <Card>
                            <CardContent style={{textAlign: "center"}}>
                                {route.name === "MN Scan" && (
                                    <DocumentScannerIcon style={{width: 210, height: 210}}/>
                                )}
                                {route.name === "Box Scan" && (
                                    <Scanner style={{width: 210, height: 210}}/>
                                )}
                                <Typography align="center" variant="h3" style={{marginTop: -10}}>
                                    {route.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            )
            cards.push(route)
        }
        setCardElements(cardElements)
    }

    return (
        <Container maxWidth={'xl'} sx={{pt: '50px'}}>
            <Grid
                className="mainContainer"
                container
                spacing={3}
            >
                {cardElements.map((card) => {
                    return card
                })}
            </Grid>
        </Container>
    )
}

export default Home;