import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";

import {IProjectSite} from '../../interfaces/types';
import ComboBox, {IOptions} from "../../components/ComboBox/ComboBox";

interface IProps {
    userProjectSites: IProjectSite[],
    selectedProject: IOptions | null,
    setSelectedProject: Dispatch<SetStateAction<IOptions | null>>,
    selectedSite: IOptions | null,
    setSelectedSite: Dispatch<SetStateAction<IOptions | null>>,
    handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

const ProjectSiteSelect = (props: IProps) => {
    const [comboBoxProjectValues, setComboBoxProjectValues] = useState<IOptions[]>([])
    const [comboBoxSiteValues, setComboBoxSiteValues] = useState<IOptions[]>([])


    useEffect(() => {
        setDistinctSelectedOption(props.userProjectSites, "projectCode", props.setSelectedProject, setComboBoxProjectValues)
        setDistinctSelectedOption(props.userProjectSites, "siteCode", props.setSelectedSite, setComboBoxSiteValues)
    }, [props.userProjectSites]);

    useEffect(() => {
        //if none selected
        if (props.selectedProject === null) {
            props.setSelectedSite(null)
            return
        }
        //filter all sites out that do not belong to the selected project
        let sites = props.userProjectSites.filter((project: IProjectSite) => project.projectCode === props.selectedProject?.code)
        setDistinctSelectedOption(sites, "siteCode", props.setSelectedSite, setComboBoxSiteValues)
    }, [props.selectedProject]);

    useEffect(() => {
        if (props.selectedSite === null) {
            setDistinctSelectedOption(props.userProjectSites, "projectCode", props.setSelectedProject, setComboBoxProjectValues)
            return;
        }
        //filter all projects out that do not belong to the selected site
        let projects = props.userProjectSites.filter((project: IProjectSite) => project.siteCode === props.selectedSite?.code)
        setDistinctSelectedOption(projects, "projectCode", props.setSelectedProject, setComboBoxProjectValues)
    }, [props.selectedSite]);


    function distinctOptionsArray(array: IOptions[]): IOptions[] {
        array = array.filter((data, index, self) =>
                index === self.findIndex((t) => (
                    t.label === data.label && t.code === data.code
                ))
        )
        return array
    }

    type key = "projectCode" | "siteCode"
    //If only one distinct code for site or project select it in the combobox
    function setDistinctSelectedOption(array: IProjectSite[], k: key, cbSetSelected: Dispatch<SetStateAction<IOptions | null>>, cbSetComboBox: Dispatch<SetStateAction<IOptions[]>>) {
        let arrOptions: IOptions[] = array.map((project: IProjectSite) => {
            if (k === "projectCode")
                return {label: project.projectName, code: project.projectCode}
            else
                return {label: project.siteName, code: project.siteCode}
        })

        if (arrOptions.length === 1) {
            const project = array[0]
            if (k === "projectCode")
                cbSetSelected({label: project.projectName, code: project[k]})
            else
                cbSetSelected({label: project.siteName, code: project[k]})
        }
        cbSetComboBox(distinctOptionsArray(arrOptions))
    }

    return (
        <>
            <Grid item xs={3}>
                <ComboBox options={comboBoxProjectValues} id={"project_combobox"} label={"Project"}
                          value={props.selectedProject} setValue={props.setSelectedProject} onKeyDown={props.handleKeyPress}/>
            </Grid>
            <Grid item xs={3}>
                <ComboBox options={comboBoxSiteValues} id={"site_combobox"} label={"Site"} value={props.selectedSite}
                          setValue={props.setSelectedSite} onKeyDown={props.handleKeyPress}/>
            </Grid>
        </>
    );
}
export default ProjectSiteSelect;