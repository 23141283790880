import * as XLSX from "xlsx";
import {IRow} from "../../components/BoxReviewTable/BoxReviewTable";

export function getNumberOfColumns(worksheet: XLSX.WorkSheet) {
    if (worksheet['!ref'] === undefined) return 0;

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    return range.e.c - range.s.c + 1;
}

export function generateUppercaseAlphabetArray(numberOfColumns: number) {
    // Initialize an empty array
    let result = [];
    let start = 65;
    for (let i = 0; i < numberOfColumns; i++) {
        result.push(String.fromCharCode(start + i));
    }
    return result;
}

export function combine2PatId(type: string, number: string, yyyy: string, cube: string): string {
    if (type === "" || number === "" || yyyy === "" || cube === "") {
        return ""
    }
    try {
        return `${type.trim()}${number}-${yyyy}-${cube.toString().trim()}`
    } catch (ex: any) {
        console.log(ex)
        return ex.toString();
    }
}

export function validateTMAHeaders(object: any) : boolean {
    return (
        Object.hasOwn(object, "Unique TMA sample ID") &&
        Object.hasOwn(object, "TMA Block ID") &&
        Object.hasOwn(object, "TMA Block Name") &&
        Object.hasOwn(object, "Donor Block ID") &&
        Object.hasOwn(object, "Donor Block Images") &&
        Object.hasOwn(object, "Donor Core Height") &&
        Object.hasOwn(object, "Annotation Color") &&
        Object.hasOwn(object, "Annotation Name")
    )
}

export function isControlObject(obj: any) {
    const keys = Object.keys(obj);
    let emptyCount = 0;

    // Count the number of empty string values
    for (let key of keys) {
        if (obj[key] === "") {
            emptyCount++;
        }
    }

    // Return true if most key-value pairs are empty strings
    return emptyCount >= 2;
}

export function unique(arr: String[]) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
}