import { Button } from '@mui/material';
import React, { useEffect } from 'react';

interface FileUploadProps {
    onFileSelected: (file: File, type: string) => void;
    type: string,
    refresh: number,
    disabled?: boolean
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelected, type, refresh, disabled }) => {
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        clearComponent();
    }, [refresh]);

    useEffect(() => {
        setLoading(false);
    }, [loading]);

    const clearComponent = () => {
        setLoading(true);
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            onFileSelected(file, type);
        }
    };

    if (loading) {
        return <p>Loading</p>
    }
    if (disabled !== undefined) {
        return <input disabled={disabled} type="file" accept=".xlsx, .xls" onChange={handleFileChange} />;
    }
    return <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />;

};

export default FileUpload;
