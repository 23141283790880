import { useState, MouseEvent, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//Mui
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';

//internal
import fullLogo from "./image002.png";
import apiManager from "../../utils/apiManager";
import { IRoute } from "../../interfaces/types";

interface NavBarProps {
    userName?: string,
    pages: Array<IRoute>
    authenticated: boolean
}

const ResponsiveAppBar = (props: NavBarProps) => {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [selectedNav, setSelectedNav] = useState<null | string>(null);

    const location = useLocation();
    useEffect(() => {
        setSelectedNav(location.pathname)
    }, [location.pathname]);



    const handleClickLink = (link: string) => {
        setSelectedNav(link)
    };

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLogoutEvent = async () => {
        await apiManager.logout()
    }

    return (
        <AppBar position="static" style={{ paddingTop: 10 }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Grid container sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}>
                        {props.userName && (
                            <>
                                <Grid item xl={5} lg={7} md={8} style={{display: "flex"}}>
                                    <img src={fullLogo} alt="Logo" height={50} />
                                    <div>
                                        <Typography
                                            variant="h5"
                                            component="a"
                                            href="/home"
                                            sx={{
                                                mr: 3,
                                                ml: 3,
                                                mt: 1.5,
                                                fontFamily: 'roboto',
                                                fontSize: 35,
                                                fontWeight: 800,
                                                letterSpacing: '.2rem',
                                                color: 'inherit',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            |
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="h5"
                                            component="a"
                                            href="/home"
                                            sx={{
                                                // mr: 3,
                                                mt: 1.5,
                                                fontFamily: 'roboto',
                                                fontSize: 15,
                                                fontWeight: 500,
                                                letterSpacing: '.2rem',
                                                color: 'inherit',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            Connection Manager
                                        </Typography><br />
                                        <Typography
                                            variant="h5"
                                            component="a"
                                            href="/home"
                                            sx={{
                                                // mr: 3,
                                                mt: 1.5,
                                                fontFamily: 'roboto',
                                                fontSize: 15,
                                                fontWeight: 500,
                                                letterSpacing: '.2rem',
                                                color: 'inherit',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <strong>⸺ Tengistjóri</strong>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xl={5} lg={3} md={2}>
                                    {props.authenticated && props.pages.map((page, index: number) => {
                                        return page.showInMenu ? (
                                            <Button
                                                key={index}
                                                component={Link}
                                                to={page.path}
                                                // variant="outlined"
                                                onClick={() => handleClickLink(page.path)}
                                                sx={{
                                                    my: 1,
                                                    mr: 1,
                                                    color: 'white',
                                                    borderColor: "white",
                                                    textDecoration: page.path === selectedNav ? 'underline' : null
                                                }}
                                            >
                                                {page.name}
                                            </Button>
                                        ) : null
                                    })}
                                </Grid>
                                <Grid item xl={2} lg={2} md={2} sx={{ textAlign: "right" }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            // mt: 2.9,
                                            my: 1,
                                            fontFamily: 'roboto',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: 'inherit',
                                            display: "inline",
                                            textDecoration: 'none',
                                        }}
                                    >
                                        {props.userName}
                                    </Typography>
                                    {props.authenticated &&
                                        <Tooltip title="Logout">
                                            <Button
                                                onClick={handleLogoutEvent}
                                                sx={{
                                                    my: 1,
                                                    color: 'white',
                                                    ml: -2,
                                                }}
                                            >
                                                <LogoutIcon />
                                            </Button>
                                        </Tooltip>
                                    }
                                </Grid>
                            </>
                        )}
                        {!props.userName && (
                            <>
                                <Grid item xl={10} xs={9}>
                                    <img src={fullLogo} alt="Logo" height={50} />
                                </Grid>
                                <Grid item xs={3} xl={2}>
                                    <Typography
                                        variant="h5"
                                        component="a"
                                        href=""
                                        sx={{
                                            // mr: 3,
                                            mt: 1.5,
                                            fontFamily: 'roboto',
                                            fontSize: 15,
                                            fontWeight: 500,
                                            letterSpacing: '.2rem',
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Connection Manager
                                    </Typography><br />
                                    <Typography
                                        variant="h5"
                                        component="a"
                                        href=""
                                        sx={{
                                            // mr: 3,
                                            mt: 1.5,
                                            fontFamily: 'roboto',
                                            fontSize: 15,
                                            fontWeight: 500,
                                            letterSpacing: '.2rem',
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <strong>⸺ Tengistjóri</strong>
                                    </Typography>
                                </Grid>
                            </>
                        )}


                    </Grid>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {props.authenticated && props.pages.map((page, index: number) => {
                                return page.showInMenu ? (
                                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ) : null
                            })}
                        </Menu>
                    </Box>
                    <Grid container sx={{ display: { md: 'none' }, ml: 1 }}>
                        <Grid item xs={11}>
                            <Box sx={{ mr: 1 }}>
                                <img src={fullLogo} alt="Logo" height={50} />
                            </Box>
                        </Grid>
                        <Grid item xs={1} sx={{ display: { xs: 'none' } }}>
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href=""
                                sx={{
                                    mr: 3,
                                    mt: 1.5,
                                    fontFamily: 'roboto',
                                    fontSize: 18,
                                    fontWeight: 500,
                                    letterSpacing: '.2rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Connection Manager
                            </Typography>
                        </Grid>
                    </Grid>

                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
