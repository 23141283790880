import React, {useEffect} from 'react';
import Container from "@mui/material/Container";
import apiManager from "../../utils/apiManager";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {Alert} from "@mui/material";
import {useUserContext} from "../../components/UserContext/UserContext";
import {GridBreak} from "../../components/GridUtils/GridBreak";
import {IProjectSite, IUser} from "../../interfaces/types";


const Login = () => {
    const navigate = useNavigate();
    const {state, dispatch} = useUserContext();

    const [phoneNumber, setPhoneNumber] = React.useState<string>("")
    const [submitted, setSubmitted] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>("")

    useEffect(() => {
        if (state.user.authenticated) {
            navigate('/home')
        }
    }, []);

    const validatePhoneNumber = (phoneNumber: string) => {
        let re = /^\d{7}$/;
        return re.test(phoneNumber);
    };


    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            login().catch(e => console.error(e));
        }
    };

    async function login() {
        setSubmitted(true);
        setError(false)
        if (validatePhoneNumber(phoneNumber)) {
            try {
                let res: IUser = await apiManager.phoneLogin(phoneNumber);
                let projectSites: IProjectSite[] = await apiManager.getUserProjectSites();
                dispatch({
                    type: 'SET_USER',
                    payload: {authenticated: true, name: res.name, ssn: res.ssn, projectSites: projectSites}
                });
                navigate('/home');
            } catch (e: any) {
                setError(true)
                setErrorMessage(e.response.data.statusMessage)
            }
            setSubmitted(false);
        } else {
            setError(true)
            setErrorMessage("Invalid phone number")
            setSubmitted(false);
        }
    }

    return (
        <Container maxWidth={'xl'} sx={{pt: '50px'}}>
            <Grid
                container
                spacing={2}
                direction="row"
            >
                <Grid item xs={12}>
                    <Typography variant={'h4'}>
                        Login
                    </Typography>
                </Grid>
                <GridBreak/>
                <Grid item xs={6}>
                    <Typography variant={'body1'}>
                        To login please enter your phone number and complete the identification process on your phone.
                    </Typography>
                </Grid>
                <GridBreak/>
                <Grid item xs={6}>
                    <Typography variant={'body1'}>
                        It is only possible to log into this site with an electronic ID (Rafræn skilríki).
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", paddingTop: "30px"}}>
                    <Grid item>
                        <TextField
                            required
                            id="outlined-phone-number"
                            label="Phone Number"
                            type="tel"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                    </Grid>
                    <Grid item alignItems="stretch" style={{display: "flex"}}>
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={login}
                            disabled={submitted}
                            style={{marginLeft: "10px"}}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
                {error &&
                    <Grid item xs={4}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
export default Login;