import axios, {AxiosResponse} from "axios";
import {IProjectSite, IUser} from "../interfaces/types";

async function phoneLogin(phoneNumber:string): Promise<IUser> {
    let res: AxiosResponse = await axios.get('/auth/v1/phoneLogin', {params:{phoneNumber: phoneNumber}})
    return res.data
}

async function isLoggedIn(): Promise<IUser> {
    let res: AxiosResponse = await axios.get('/auth/v1/user')
    if(res.data.authenticated) {
        res.data.projectSites = await getUserProjectSites()
    }
    return res.data
}

async function logout(): Promise<void> {
    window.location.href = "/auth/v1/logout";
}

async function getUserProjectSites(): Promise<IProjectSite[]> {
    let res = await axios.get('/api/v1/getUserProjectSites', {})
    return res.data
}

async function addMnLabel( personId: string, barcode: string, projectCode: string, siteCode: string | null): Promise<void> {
    let res = await axios.post('/api/v1/addMnLabel',
        {
            personId: personId,
            projectCode: projectCode,
            barcode: barcode,
            siteCode: siteCode
        })
    return res.data
}

async function addBnLabel( projectCode: string, siteCode: string, barcode: string, layers: number, slots: any ): Promise<void> {
    let res = await axios.post('/api/v1/addBnLabel',
        {
            barcode: barcode,
            layers: layers,
            projectCode: projectCode,
            siteCode: siteCode,
            slots: slots
        })
    return res.data
}

let apiManager = {
    phoneLogin: phoneLogin,
    logout: logout,
    getUserProjectSites: getUserProjectSites,
    addMnLabel: addMnLabel,
    isLoggedIn: isLoggedIn,
    addBnLabel: addBnLabel
}
export default apiManager

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401 && error.response.data === "Session expired") {
            console.log(error.response.data);
            window.location.href = "/auth/v1/logout"
        }
        return Promise.reject(error);
    }
);

