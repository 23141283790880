let primaryColor = "#4A4A4A";
export default {
    palette: {
        primary: {
            main: "#0096FF"
        },
        secondary: {
            main: "#6e6e6e"
        },
        text: {
            primary: primaryColor,
            secondary: "#6E6E6E",
            hint: "#B9B9B9",
        }
    },
    overrides: {
        MuiListItemText: {
            primary: {
                color: primaryColor
            },
        },
    },
};
