import React from 'react';
import Grid from "@mui/material/Grid";
import {CircularProgress} from "@mui/material";

const loading = () => {
    return (
        <div style={{display: 'flex'}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '70vh'}}
            >

                <Grid item xs={3}>
                    <CircularProgress size={100}/>
                </Grid>
            </Grid>
        </div>
    );
}
export default loading