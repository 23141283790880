import React, {createContext, Dispatch, useContext, useReducer} from "react";
import {IProjectSite} from "../../interfaces/types";

interface IUserContext {
    state: { user: User },
    dispatch: Dispatch<UserAction>;
}


type User = {
    authenticated: boolean;
    name: string;
    ssn: string;
    projectSites: IProjectSite[];
};

type UserAction = { type: 'SET_USER'; payload: User }

type State = {
    user: User;
};

// Initial state
const initialState: State = {
    user: {
        authenticated: false,
        name: "",
        ssn: "",
        projectSites: [],
    },
};

export const UserContext = createContext<IUserContext | undefined>(undefined);

// Reducer function
function userReducer(state: State, action: UserAction): State {
    switch (action.type) {
        case 'SET_USER':
            return {...state, user: action.payload};
        default:
            return state;
    }
}

// Context Provider component
type UserProviderProps = {
    children: React.ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({children}) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    return (
        <UserContext.Provider value={{state, dispatch}}>
            {children}
        </UserContext.Provider>
    )
};

// Custom hook for using context safely
export const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};