import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Alert, Stack, tableCellClasses} from "@mui/material";
import {styled} from '@mui/material/styles';

export interface IRow {
    kt: string | null,
    patID: string | null,
    position: string | null,
    a_color: string | null,
    additionalData: string | null
}

interface IProps {
    data: IRow[]
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function BoxReviewTable(props: IProps) {
    return (
        <TableContainer component={Paper} sx={{maxHeight: 640, minHeight: 640}}>
            <Table sx={{minWidth: 650}} aria-label="review table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Kennitala</StyledTableCell>
                        <StyledTableCell>PatId</StyledTableCell>
                        <StyledTableCell>Position</StyledTableCell>
                        <StyledTableCell>Color</StyledTableCell>
                        <StyledTableCell>Additional Data</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.length === 0 &&
                        <TableRow
                            key={-1}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                        <TableCell colSpan={3}>
                            <Stack sx={{width: '100%'}}>
                                <Alert severity="info">No Data imported</Alert>
                            </Stack>
                        </TableCell>
                        </TableRow>
                    }
                    {props.data.map((row: IRow, index: number) => (
                        <StyledTableRow
                            key={index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <StyledTableCell component="th" scope="row">
                                {row.kt}
                            </StyledTableCell>
                            <StyledTableCell>{row.patID}</StyledTableCell>
                            <StyledTableCell>{row.position}</StyledTableCell>
                            <StyledTableCell>{row.a_color}</StyledTableCell>
                            <StyledTableCell>{row.additionalData}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
