import React, {useEffect, useState} from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Alert} from "@mui/material";
import {GridBreak} from '../../components/GridUtils/GridBreak';
import FileUpload from "../../components/FileUpload/FileUpload";
import * as XLSX from 'xlsx';
import BoxReviewTable, {IRow} from "../../components/BoxReviewTable/BoxReviewTable";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import apiManager from "../../utils/apiManager";
import Swal from "sweetalert2";
import ComboBox, {IOptions} from "../../components/ComboBox/ComboBox";
import {useUserContext} from "../../components/UserContext/UserContext";
import kennitala from "kennitala";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ProjectSiteSelect from "../../components/ProjectSiteSelect/projectSiteSelect";
import {IProjectSite} from "../../interfaces/types";
import {
    combine2PatId,
    generateUppercaseAlphabetArray,
    getNumberOfColumns, isControlObject, unique,
    validateTMAHeaders
} from "./boxScanUtils";

class TMAData {
    kt: string;
    patId: string;
    position: string;
    annotationColor: string | null;
    additionalInfo: Record<string, unknown>;

    constructor(kt: string, patId: string, position: string, annotationColor: string | null, additionalInfo: Record<string, unknown>) {
        this.kt = kt;
        this.patId = patId;
        this.position = position;
        this.annotationColor = annotationColor;
        this.additionalInfo = additionalInfo;
    }
}

class ConnectionListEntry {
    kt: string;
    patId: string;
    additionalInfo: any; // Generic type T for additional info

    constructor(kt: string, patId: string, additionalInfo: any) {
        this.kt = kt;
        this.patId = patId;
        this.additionalInfo = additionalInfo;
    }
}

const BoxScan = () => {
    const {state} = useUserContext();

    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [barcode, setBarcode] = useState<string>("")

    const layerOptions: IOptions[] = [{label: "1", code: "1"}, {label: "2", code: "2"}, {
        label: "3",
        code: "3"
    }, {label: "4", code: "4"}, {label: "5", code: "5"}]
    const [layers, setLayers] = useState<IOptions | null>(null)
    const [connectionList, setConnectionList] = useState<ExcelRow[]>([]);
    const [tmaUploadDisabled, setTmaUploadDisabled] = useState<boolean>(true)
    const [connectionsUploadDisabled, setConnectionsUploadDisabled] = useState<boolean>(false)
    const [tmaList, setTmaList] = useState<ExcelRow[]>([]);
    const [tableData, setTableData] = useState<IRow[]>([]);
    const [refreshConnections, doRefreshConnections] = useState(0);
    const [refreshTMA, doRefreshTMA] = useState(0);
    const [selectedProject, setSelectedProject] = useState<IOptions | null>(null)
    const [selectedSite, setSelectedSite] = useState<IOptions | null>(null)

    const EXCLUDE_COL = "Exl. from send";

    interface ExcelRow {
        [key: string]: any;
    }


    useEffect(() => {
        setTableData(createTableDate())
    }, [tmaList]);

    const handleMouseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        submit().catch(e => console.error(e));
    }

    async function validateInput(project: IProjectSite | undefined) {
        if (!layers) {
            throw new Error("Number of layers not selected")
        }
        if (project === undefined) {
            throw new Error("Project not found")
        }
        if (barcode === "") {
            throw new Error("Barcode missing")
        }
        if (project.projectCode === "") {
            throw new Error("Project code missing")
        }
        return project
    }

    async function submit() {
        setError(false)
        setErrorMessage("")
        try {
            if (!layers) {
                setError(true)
                setErrorMessage("Layers not selected")
                return
            }
            if (selectedProject === null) {
                setError(true)
                setErrorMessage("Project not selected")
                return
            }
            if (selectedSite === null) {
                setError(true)
                setErrorMessage("Site not selected")
                return
            }

            let project: IProjectSite | undefined = state.user.projectSites.find((project: IProjectSite) => project.projectCode === selectedProject.code && project.siteCode === selectedSite.code)
            let validatedProject: IProjectSite = await validateInput(project)

            await apiManager.addBnLabel(validatedProject.projectCode, validatedProject.siteCode, barcode, parseInt(layers.code), tableData.map((row) => ({
                personId: row.kt,
                slot: row.position,
                properties: row.additionalData
            })))

            Swal.fire({
                title: "Success",
                text: "Label added",
                icon: "success",
                confirmButtonText: "Ok"
            })
            setBarcode("")
        } catch (e: any) {
            console.error(e);
            if (e.name === "AxiosError") {
                setError(true)
                setErrorMessage(e.response.data.statusMessage)
                return
            }
            setError(true)
            setErrorMessage(e.message)
        }
    }

    const handleFileSelected = (file: File, type: string) => {
        try {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target?.result;
                if (typeof binaryStr === 'string') {
                    const workbook = XLSX.read(binaryStr, {type: 'binary'});
                    if (type === "TMA") {

                        //validate
                        const lookupSheet = workbook.SheetNames[0];
                        const lookupWorksheet = workbook.Sheets[lookupSheet];
                        const lookupData = XLSX.utils.sheet_to_json(lookupWorksheet, {defval: "", raw: false});
                        if (workbook.SheetNames.length !== 3) {
                            Swal.fire({
                                title: "Incorrect number of sheets in file",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The TMA file should have exactly 3 sheets. The current file has ${workbook.SheetNames.length} sheets.`
                            })
                            doRefreshTMA(prev => prev + 1);
                            return;
                        }

                        if (getNumberOfColumns(lookupWorksheet) !== 8) {
                            Swal.fire({
                                title: "Incorrect number of columns in lookup tab",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The lookup tab should have 8 columns. The current file has ${getNumberOfColumns(lookupWorksheet)} columns.`
                            })
                            doRefreshTMA(prev => prev + 1);
                            return;
                        }

                        const layoutSheet = workbook.SheetNames[1];
                        const layoutWorksheet = workbook.Sheets[layoutSheet];
                        let layoutData = XLSX.utils.sheet_to_json(layoutWorksheet, {defval: "", raw: false});

                        if (!validateTMAHeaders(lookupData.reduce((fewest, current) => {
                            // @ts-ignore
                            return Object.keys(current).length < Object.keys(fewest).length ? current : fewest;
                        }, lookupData[0]))) {
                            Swal.fire({
                                title: "Inproperly named columns or missing data",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The TMA file needs to have specific header names and data in every one of them. Make sure they are correct.`
                            })
                            doRefreshTMA(prev => prev + 1);
                            return;
                        }

                        // remove control elements from layoutData
                        if (layoutData.length > 0) {
                            if (isControlObject(layoutData[0])) {
                                layoutData.shift()
                            }
                            if (isControlObject(layoutData[layoutData.length - 1])) {
                                layoutData.pop()
                            }
                        }

                        let data: TMAData[] = []
                        let rowLetter = 'A'

                        let notMatchingIds: any = [];
                        let notMatchingPatId: String[] = [];
                        for (let i = 0; i <= layoutData.length - 1; i++) {
                            let row: any = layoutData[i]

                            Object.entries(row).forEach((entry, index) => {
                                const [key, value] = entry;
                                let newkey: string = rowLetter + index

                                let valueNum = parseInt(value as string)
                                if (!isNaN(valueNum)) {
                                    //find row in lookupData matching value
                                    const lookupRow: Record<string, unknown> = lookupData.find((row: any) => row['Unique TMA sample ID'] == valueNum) as Record<string, unknown>
                                    // needs catching?
                                    if (lookupRow) {
                                        let patId: any = lookupRow['Donor Block ID']
                                        let annotationColor: any = lookupRow['Annotation Color']

                                        //find kt in connectionList matching patId
                                        const connectionRow = connectionList.find((row: any) => row.patId == patId)
                                        if (connectionRow) {
                                            let additionalInfo = JSON.parse(JSON.stringify(connectionRow.additionalInfo)) // needs to be deep copy
                                            additionalInfo['AnnotationColor'] = annotationColor
                                            data.push({
                                                kt: connectionRow.kt,
                                                patId: patId,
                                                position: newkey,
                                                annotationColor: annotationColor,
                                                additionalInfo: additionalInfo
                                            })
                                        } else {
                                            notMatchingPatId.push(patId)
                                        }
                                    } else {
                                        notMatchingIds.push(valueNum)
                                    }
                                }
                            });
                            rowLetter = String.fromCharCode(rowLetter.charCodeAt(0) + 1)
                        }

                        if (notMatchingIds.length > 0) {
                            Swal.fire({
                                title: "Element from sheet 2 not found in sheet 1",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The TMA file needs to have entries in sheet 1 for every element in sheet 2. Entries were found for elements ${notMatchingIds.join(", ")} in sheet 2 but not in sheet 1.`
                            })
                            doRefreshTMA(prev => prev + 1);
                            return;
                        }
                        if (notMatchingPatId.length > 0) {
                            Swal.fire({
                                title: "PatId in TMA file not found in connection list",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The following patId ${unique(notMatchingPatId).join(", ")} were not found in the connection list.`
                            })
                            doRefreshTMA(prev => prev + 1);
                            return;
                        }
                        setTmaList(data as ExcelRow[]);
                        setTmaUploadDisabled(true);

                    }

                    if (type === "connectionList") {
                        if (workbook.SheetNames.length > 1) {
                            Swal.fire({
                                title: "More than 1 sheet in file",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The connections file should only have 1 sheet. The current file has ${workbook.SheetNames.length} sheets.`
                            })
                            doRefreshConnections(prev => prev + 1);
                            return;
                        }
                        const sheetName: string = workbook.SheetNames[0];
                        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
                        const numberOfColumns = getNumberOfColumns(worksheet)

                        const simpleHeaders = generateUppercaseAlphabetArray(numberOfColumns)
                        const jsonData: ExcelRow = XLSX.utils.sheet_to_json(worksheet, {
                            header: simpleHeaders,
                            defval: "",
                            raw: false
                        });

                        const headers: ExcelRow = jsonData.shift();

                        // Step 1: Get the keys of the headersobject
                        const keys = Object.keys(headers);
                        // Step 2: Sort the keys to ensure they are in alphabetical order
                        keys.sort();
                        // Step 3: Use the sorted keys to create an array of the corresponding values
                        const valuesInOrder = keys.map(key => headers[key]);
                        // Step 4: Find the index of the element "TMA"
                        const indexOfExclude = valuesInOrder.indexOf(EXCLUDE_COL);
                        // Step 5: Slice the array up to (and including) the index of "TMA"
                        if (indexOfExclude === -1) {
                            Swal.fire({
                                title: "Missing exclude column",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `The connections file should have an exclude column.`
                            })
                            doRefreshConnections(prev => prev + 1);
                            return;
                        }

                        let incorrectKts: string[] = [];
                        let data: ConnectionListEntry[] = jsonData.map((row: any) => {
                            if (!kennitala.isValid(kennitala.sanitize(row.A)!)) {
                                incorrectKts.push(row.A)
                            }
                            let kt = row.A
                            let patId = combine2PatId(row.B, row.C, row.D, row.E)
                            let flokkur = row.B
                            delete row['A']
                            delete row['B']
                            delete row['C']
                            delete row['D']
                            delete row['E']
                            let additionalInfo: Record<string, unknown> = {}
                            additionalInfo.Flokkur = flokkur
                            for (let entry of Object.entries(row)) {
                                const [key, value] = entry;
                                let keyName = headers[key]
                                if (keyName === EXCLUDE_COL) {
                                    break
                                }
                                additionalInfo[keyName] = value
                            }

                            return new ConnectionListEntry(kt, patId, additionalInfo)
                        })

                        if (incorrectKts.length > 0) {
                            Swal.fire({
                                title: "Invalid kennitala",
                                icon: "error",
                                confirmButtonText: "Ok",
                                html: `
                                The following kennitala are <b>invalid</b>:
                                ${incorrectKts.map(x => `<p>${x}</p>`).join('')}
                              `
                            })
                            doRefreshConnections(prev => prev + 1);
                            return;
                        }
                        setConnectionsUploadDisabled(true)
                        setTmaUploadDisabled(false)
                        setConnectionList(data as ExcelRow[]);
                    }
                }
            };
            reader.readAsBinaryString(file);
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                title: "Error parsing excel document",
                icon: "error",
                confirmButtonText: "Ok",
                html: `There was a problem parsing the excel document. Please make sure that it is correctly formed.
              `
            })
            doRefreshConnections(prev => prev + 1);
            doRefreshTMA(prev => prev + 1);
        }

    };

    const createTableDate = (): IRow[] => {
        return tmaList.map((row: ExcelRow) => {
            const entries = Object.entries(row.additionalInfo);
            // Map each key-value pair to a string "key=value"
            const pairs = entries.map(([key, value]) => `${key}=${value}`);
            return {
                kt: row.kt,
                patID: row.patId,
                position: row.position,
                a_color: row.annotationColor,
                additionalData: pairs.join(";")
            }
        })
    }

    return (
        <Container maxWidth={'xl'} sx={{pt: '50px'}}>
            <Grid
                container
                spacing={2}
                direction="row"
            >
                <Grid item xs={12}>
                    <Typography variant={'h4'}>
                        Box Scan
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body1'}>
                        To submit, please scan a valid mn-barcode, enter the kennitala of the person the label is for
                        and select a project and site.
                    </Typography>
                </Grid>
                <GridBreak/>
                <Grid item xs={4}>
                    <Typography variant={'body1'} style={{display: "inline-block", marginRight: 5}}>
                        Connections -
                    </Typography>
                    <div style={{display: "inline-block"}}>
                        <FileUpload onFileSelected={handleFileSelected} type={'connectionList'}
                                    refresh={refreshConnections} disabled={connectionsUploadDisabled}/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <CheckBoxIcon fontSize="medium" style={{
                        color: "green",
                        display: connectionsUploadDisabled ? "inline-block" : "none"
                    }}/>
                </Grid>
                <GridBreak/>
                <Grid item xs={4}>
                    <Typography variant={'body1'} style={{display: "inline-block", marginRight: 5}}>
                        TMA file -
                    </Typography>
                    <div style={{display: "inline-block"}}>
                        <FileUpload onFileSelected={handleFileSelected} type={'TMA'} refresh={refreshTMA}
                                    disabled={tmaUploadDisabled}/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <CheckBoxIcon fontSize="medium" style={{
                        color: "green",
                        display: (connectionsUploadDisabled && tmaUploadDisabled) ? "inline-block" : "none"
                    }}/>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={12} style={{paddingBottom: "20px"}}>
                    <Button variant="outlined" disabled={!connectionsUploadDisabled} onClick={() => {
                        doRefreshConnections(prev => prev + 1);
                        doRefreshTMA(prev => prev + 1);
                        setConnectionsUploadDisabled(false);
                        setTmaUploadDisabled(false);
                        setTableData([]);
                    }}>Clear</Button>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="outlined-barcode"
                        label="Barcode"
                        value={barcode}
                        autoFocus
                        fullWidth
                        type="tel"
                        onChange={(e) => setBarcode(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ComboBox options={layerOptions} id={"layer-combobox"} label={"Number of layers"} value={layers}
                              setValue={setLayers}/>
                </Grid>
                <ProjectSiteSelect
                    userProjectSites={state.user.projectSites.filter((project: IProjectSite) => project.allowBnScan)}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    selectedSite={selectedSite}
                    setSelectedSite={setSelectedSite}
                />
                <GridBreak/>
                <Grid item xs={10}>
                    <Typography variant={'h6'}>
                        Review Data
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <BoxReviewTable data={tableData}/>
                </Grid>
                <GridBreak style={{paddingBottom: "20px"}}/>
                <Grid item alignItems="stretch" xs={2} style={{display: "flex"}}>
                    <Button
                        variant="contained"
                        size={"large"}
                        onClick={handleMouseClick}
                    >
                        Submit
                    </Button>
                </Grid>
                <GridBreak/>
                {error &&
                    <Grid item xs={10}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
export default BoxScan;